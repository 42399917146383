import { AxiosError, AxiosInstance, HttpStatusCode, isAxiosError } from 'axios'
import { jwtDecode } from 'jwt-decode'

import { requestTokenRefresh } from '../requestTokenRefresh'
import { returnError } from './helpers'

interface TokenRejectInterceptorProps {
  authenticatedAxiosClient: AxiosInstance
  refreshToken?: string
}

export function tokenRejectInterceptor({
  authenticatedAxiosClient,
  refreshToken,
}: TokenRejectInterceptorProps) {
  return async (error: AxiosError | Error) => {
    // istanbul ignore next
    if (!isAxiosError(error) || !error.config) {
      return returnError(error)
    }

    const { config: originalRequest, response } = error
    if (
      response?.status !== HttpStatusCode.Unauthorized ||
      originalRequest.url === '/api/token/refresh/'
    ) {
      return returnError(error)
    }

    if (!refreshToken) {
      return returnError(error, true)
    }

    let tokenPartsRefresh: { exp: number }
    try {
      tokenPartsRefresh = jwtDecode<{ exp: number }>(refreshToken)
    } catch (err) {
      return returnError(
        { ...(err as Error), message: 'Refresh token is malformed' },
        true
      )
    }

    const now = Math.ceil(Date.now() / 1000)
    if (tokenPartsRefresh.exp < now) {
      return returnError(error, true)
    }

    return requestTokenRefresh({
      authenticatedAxiosClient,
      originalRequest,
      refreshToken,
    })
  }
}
