import { Dispatch, createContext } from 'react'

import type { LotCardBidModalProps } from '../LotCardBidModal/LotCardBidModal'
import type { LotCardRegisterModalProps } from '../LotCardRegisterModal/LotCardRegisterModal'

export const modalsInitialState: ModalState = {
  modal: null,
  props: null,
}

export const ModalsDispatchContext =
  createContext<Dispatch<ModalAction> | null>(null)

export function modalsReducer(
  state: ModalState,
  action: ModalAction
): ModalState {
  switch (action.type) {
    case 'CLOSE':
      return modalsInitialState
    case 'OPEN':
      return action.payload
  }
}

export type NoModalState = { modal: null; props: null }
export type BidModalState = {
  modal: 'BID_NOW'
  props: Omit<LotCardBidModalProps, 'setIsOpen'>
}
export type RegisterModalState = {
  modal: 'REGISTER_NOW'
  props: Omit<LotCardRegisterModalProps, 'setIsOpen'>
}

export type ModalState = NoModalState | BidModalState | RegisterModalState

export interface ModalAction {
  type: 'CLOSE' | 'OPEN'
  payload: ModalState
}
