import getConfig from 'next/config'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { User } from '@nx/api'
import { useGetInterests } from '@nx/api/hooks'
import { useGetLaunchDarklyFlags } from '@nx/external/launch-darkly-client'
import {
  ExtraErrorMessagesProps,
  FireLoginModal,
  StyledErrorMessage,
} from '@nx/fire/login-modal'
import { Brand, EBrand } from '@nx/global-types'
import { useUserAuth } from '@nx/hooks/use-user-auth'
import { getTranslate } from '@nx/translations'
import { GTM_EVENTS, useDataLayer } from '@nx/utils/google-tag-manager'

import { StyledSkinnerResetPasswordButton } from './LoginModal.styles'
import translate from './LoginModal.translate.json'

const { publicRuntimeConfig } = getConfig()
const { text, textHTML } = getTranslate(translate)
const staticAuthPaths = [
  '/forgot-password',
  '/complete-password-reset',
  '/login',
  '/register',
]

export function LoginModal({ brand, user }: LoginModalProps) {
  const router = useRouter()
  const interests = useGetInterests(publicRuntimeConfig.WEB_API_URL)

  const { data: flags } = useGetLaunchDarklyFlags(
    publicRuntimeConfig.LAUNCHDARKLY_CLIENT_KEY
  )

  const {
    resetPassword,
    completePasswordReset,
    login,
    createAccount,
    selectInterests,
  } = useUserAuth({
    host: publicRuntimeConfig.WEB_BASE_URL,
    apiUrl: publicRuntimeConfig.WEB_API_URL,
    newPasswordFlowEnabled: !!flags?.['new-forgotten-password'],
  })

  const dataLayer = useDataLayer()

  useEffect(() => {
    if (login.status === 'success') {
      dataLayer.push({
        event: GTM_EVENTS.userLogin,
        userId: user?.customerId,
      })
    }
  }, [login.status, dataLayer, user])

  const modalProps: Partial<
    React.ComponentPropsWithoutRef<typeof FireLoginModal>
  > = staticAuthPaths.includes(router.pathname)
    ? {
        shouldCloseOnEsc: false,
        shouldCloseOnOverlayClick: false,
        hasLoginCancelButton: false,
        closeCallback: () => {
          if (typeof window !== 'undefined') window.location.assign('/')
        },
      }
    : {}

  const extraErrorMessages = ({ onClick }: ExtraErrorMessagesProps) =>
    brand === Brand.skinner ? (
      <StyledErrorMessage $level="S" aria-live="assertive">
        {textHTML('form.logIn.skinner.error', {
          resetPasswordCta: (
            <StyledSkinnerResetPasswordButton
              key="skinner-cta-link"
              $level="S"
              type="button"
              as="button"
              onClick={onClick}
            >
              {text('form.logIn.skinner.error.cta')}
            </StyledSkinnerResetPasswordButton>
          ),
        })}
      </StyledErrorMessage>
    ) : null

  return (
    <FireLoginModal
      login={login}
      resetPassword={resetPassword}
      createAccount={createAccount}
      completePasswordReset={completePasswordReset}
      selectInterests={selectInterests}
      interests={interests}
      extraErrorMessages={extraErrorMessages}
      maintenanceMode={publicRuntimeConfig.MAINTENANCE_MODE}
      newPasswordFlowEnabled={!!flags?.['new-forgotten-password']}
      {...modalProps}
    />
  )
}

interface LoginModalProps {
  brand: EBrand
  user: User | null
}
