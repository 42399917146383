import { useQuery } from '@tanstack/react-query'
import jsCookies from 'js-cookie'

import { getUser } from './getUser'

export function useGetUser(baseURL: string) {
  return useQuery({
    queryKey: ['user'],
    queryFn: () =>
      getUser(baseURL, {
        bonhams_access: jsCookies.get('bonhams_access'),
        bonhams_token: jsCookies.get('bonhams_token'),
      }),
    retry: false,
    enabled: !!jsCookies.get('bonhams_token'),
    initialData: null,
  })
}
