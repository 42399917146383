import dynamic from 'next/dynamic'
import { PropsWithChildren, ReactNode, useReducer } from 'react'

import {
  ModalsDispatchContext,
  modalsInitialState,
  modalsReducer,
} from './ModalContext'

const LotCardBidModalDynamic = dynamic(
  () =>
    import('@web/components/common/LotCardBidModal/LotCardBidModal').then(
      ({ LotCardBidModal }) => LotCardBidModal
    ),
  { ssr: false }
)

const LotCardRegisterModalDynamic = dynamic(
  () =>
    import(
      '@web/components/common/LotCardRegisterModal/LotCardRegisterModal'
    ).then(({ LotCardRegisterModal }) => LotCardRegisterModal),
  { ssr: false }
)

export const ModalManager = ({ children }: PropsWithChildren) => {
  const [modalState, dispatch] = useReducer(modalsReducer, modalsInitialState)

  let ModalComponent: ReactNode | null = null

  switch (modalState.modal) {
    case 'BID_NOW':
      ModalComponent = (
        <LotCardBidModalDynamic
          {...modalState.props}
          setIsOpen={() =>
            dispatch?.({ type: 'CLOSE', payload: modalsInitialState })
          }
        />
      )
      break
    case 'REGISTER_NOW':
      ModalComponent = (
        <LotCardRegisterModalDynamic
          {...modalState.props}
          setIsOpen={() =>
            dispatch?.({ type: 'CLOSE', payload: modalsInitialState })
          }
        />
      )
      break
  }

  return (
    <ModalsDispatchContext.Provider value={dispatch}>
      {children}
      {ModalComponent}
    </ModalsDispatchContext.Provider>
  )
}
