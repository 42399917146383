import { AxiosError, isAxiosError } from 'axios'

import { isServer } from '@nx/helpers'
import { removeAuthCookies } from '@nx/user-auth-cookies'

const DEFAULT_STATUS = 500

export function returnError(error: AxiosError | Error, removeLogin = false) {
  if (removeLogin && !isServer()) {
    removeAuthCookies()
  }
  const status = isAxiosError(error)
    ? (error.response?.status ?? DEFAULT_STATUS)
    : // istanbul ignore next
      DEFAULT_STATUS

  return Promise.reject({
    status,
    message: error.message,
    error,
  })
}
